import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import userIcon from "../../assets/images/team/1.png"
import styles from "../../assets/styles/appointments.module.css"
import { FormGroup, InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import { SearchIcon } from '../../utils/functions/Icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../utils/store';
import { fetchCancelledAppReducer } from '../../utils/store/appointment/AppointmentSlice';
import Loader from '../common/Loader';
import NoDataFound from '../common/NoDataFound';
import { Images } from '../../utils/functions/Images';
import { closeBookAppointment, openBookAppointment } from '../../utils/store/careteam/CareTeamSlice';
import BookAppointmentModal from './BookAppointmentModal';

export default function Cancelled() {
    const [searchValue, setSearchValue] = useState('')
    const [year, setYear] = useState<string | number>("0");
    const { loading, CancelledAppointment, } = useSelector((state: RootState) => state.appointment)
    const { isModalOpenAppointment, particularData } = useSelector((state: RootState) => state.careteam)

    const dispatch = useDispatch<AppDispatch>()


    useEffect(() => {
        if (searchValue) {
            const cleanFunction = setTimeout(() => {
                dispatch(fetchCancelledAppReducer({ search: searchValue || '', id: 3, filter: year }));
            }, 1000);
            return () => clearTimeout(cleanFunction);
        } else {
            dispatch(fetchCancelledAppReducer({ search: searchValue || '', id: 3, filter: year }));
        }
    }, [searchValue, year]);

    const handleCloseModal = () => {
        dispatch(closeBookAppointment());
    }
    const handleBook = async (data: any) => {
        dispatch(openBookAppointment({
            ...data,
            name: data?.doctorName,
            reschedule: true,
            appointmentId: data?.appointmentId,
            searchValue: searchValue,
            year: year
        }))
    }
    return (
        <div>
            <div className={'mb-6 flex justify-between items-center w-full max-w-full'}>
                <FormGroup className="customFormGroup w-1/2">
                    <TextField
                        className={`custumTextField ${styles.InputStyle}`}
                        id="searchValue"
                        name="searchValue"
                        onChange={(e) => setSearchValue(e.target.value)}
                        value={searchValue}
                        placeholder='Search by doctor or specialising in'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormGroup>
                <FormGroup className="customFormGroup w-36">
                    <Select
                        className={`custumTextField profileInput`}
                        placeholder="Date"
                        defaultValue={"0"}
                        value={year}
                        onChange={(e) => setYear(e.target?.value)}
                    >
                        <MenuItem selected value="0">All</MenuItem>
                        <MenuItem value="1">Last Month</MenuItem>
                        <MenuItem value="2">6 Months</MenuItem>
                        <MenuItem value="3">1 Year</MenuItem>
                    </Select>
                </FormGroup>
            </div>
            {
                loading ? <Loader />
                    :
                    <TableContainer component={Paper} className='TableContainer'>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead className='custumTableHead'>
                                <TableRow>
                                    <TableCell>Patient Name</TableCell>
                                    <TableCell>Doctor Name</TableCell>
                                    <TableCell align="right">Date</TableCell>
                                    <TableCell align="right">Time</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            {
                                CancelledAppointment?.length > 0 ?
                                    <TableBody>
                                        {
                                            CancelledAppointment?.map((item, index) => {
                                                return (
                                                    <TableRow
                                                        key={index}
                                                        className='custumTableRow'
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            <div className={styles.doctorSec}>
                                                                <img src={item?.profilePic ? item?.profilePic : Images.avtarIcon} alt="user icon" />
                                                                {item?.patientName}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item?.doctorName}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item?.date}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item?.time}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <button className={`outLineBtn ${styles.downloadPresBtn}`} onClick={() => handleBook(item)}>Reschedule Appointment</button>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }

                                    </TableBody>
                                    : <TableBody>
                                        <TableRow
                                            className='custumTableRow'
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" colSpan={5}>
                                                <NoDataFound />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                            }
                        </Table>
                    </TableContainer>
            }
            <BookAppointmentModal
                isModalOpen={isModalOpenAppointment}
                handleCloseModal={handleCloseModal}
                particularData={particularData}
            />
        </div>
    );
}