import React from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import { useNavigate } from 'react-router-dom'
import styles from "../../assets/styles/labtest.module.css"
import AllPatientTable from '../../components/profile/AllPatientTable'

const AllPatient = () => {
    const navigate = useNavigate();

    return (
        <CustomContainer>
            <div className='AllPageMain'>
                <div className='HeadAndButton'>
                    <h2>All Patients</h2>
                </div>
                <div className={styles.labTestMain}>
                    <AllPatientTable />
                </div>
            </div>
        </CustomContainer>
    )
}

export default AllPatient