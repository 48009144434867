import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import styles from "../../assets/styles/rpmmanagement.module.css"
import { useNavigate } from 'react-router-dom'
import { ConnectIcon, ConnectedIcon } from '../../utils/functions/Icons'

const RPMManagementTable = () => {
    const navigate = useNavigate();
    const handleViewDetails = () => {
        navigate('/RPMAlert/details')
    }
    const handleConnect = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        alert("Clicked")
    }
    return (
        <TableContainer component={Paper} className='TableContainer'>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className='custumTableHead'>
                    <TableRow className={styles.completeTableRow}>
                        <TableCell></TableCell>
                        <TableCell>Patient Name</TableCell>
                        <TableCell align="right">Date & Time</TableCell>
                        <TableCell align="right">Type of alert</TableCell>
                        <TableCell align="right">Status</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Array.from({ length: 8 }).map((_, index) => {
                            return (
                                <TableRow
                                    key={index}
                                    className={`custumTableRow cursor-pointer`}
                                    onClick={() => handleViewDetails()}
                                    title={'Click to view details'}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <div className={styles.doctorSec}>
                                            {
                                                index === 0 ?
                                                    <ConnectedIcon />
                                                    :
                                                    <ConnectIcon />
                                            }
                                        </div>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        Vipun Bhardwaj
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        24th May, 24  (10:00 am)
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <div className={styles.typeOfAlert}>Heart beat</div>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <div className={`${styles.typeOfAlert} ${styles.Alert}`}>ALERT !!!</div>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <button
                                            className={`${styles.connectText}`}
                                            onClick={(event) => handleConnect(event)}
                                        >Chat Now
                                        </button>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }

                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default RPMManagementTable