import CustomContainer from "../../components/common/CustomContainer"
import styles from "../../assets/styles/help.module.css"
import { FormGroup, FormLabel, TextareaAutosize } from "@mui/material"
import { FormEvent, useState } from "react"
import thanksImg from "../../assets/images/common/contactthanks.png"
import { helpSendEmailApi } from "../../utils/api/services"
import { toast } from "react-toastify"
import BtnLoader from "../../components/common/BtnLoader"
const Help = () => {
    const [isSubmited, setIsSubmited] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false);
    const [message, setMessage] = useState('')

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            setBtnLoading(true)
            const result = await helpSendEmailApi({
                message
            })
            if (result?.success) {
                setBtnLoading(false)
                toast.success(result?.message)
                setIsSubmited(true)
                setMessage('')
            } else {
                setBtnLoading(false)
                toast.error(result?.message)
            }
        } catch (error: any) {
            setBtnLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }

    return (
        <CustomContainer>
            <div className='AllPageMain'>
                <div className='HeadAndButton'>
                    <h2>Help</h2>
                </div>
                <div className={styles.helpMain}>
                    <div className="grid grid-cols-1 gap-6">
                        <div className={styles.contactSec}>
                            <div className={styles.contactSecBox}>
                                <form noValidate={false} onSubmit={handleSubmit}>
                                    <FormGroup className="customFormGroup">
                                        <p className={styles.didnotFind}>Need any assistance?</p>
                                        <FormLabel className={`customFormLabel ${styles.contactText}`}>Contact us</FormLabel>
                                        {
                                            isSubmited ? <div className={styles.ThanksForChoose}>
                                                <h4>Our team will look into your query and get back to you in 24hr.</h4>
                                                <img src={thanksImg} alt="thanks image" />
                                                <p>Thanks for choosing AkosMD</p>
                                            </div>
                                                :
                                                <TextareaAutosize
                                                    minRows={5}
                                                    className={`custumTextField profileInput w-full`}
                                                    placeholder="Type your concern"
                                                    name="message"
                                                    required
                                                    value={message}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                />
                                        }
                                    </FormGroup>
                                    {
                                        !isSubmited &&
                                        <button type="submit" className="fillButton w-full mt-6" disabled={btnLoading}> {btnLoading && <BtnLoader />}SEND</button>
                                    }
                                    <hr className="hrLine" />
                                    <div className={styles.MailUsContactUs}>
                                        <p>Mail us at : info@akosmd.in</p>
                                        <p>Contact us : +91 8595461929</p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </CustomContainer>
    )
}

export default Help
