import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface TeamTypes {
  CareTeamData: CareTeamTypes[];
  MyCareTeamData: CareTeamTypes[];
  loading: boolean;
  SelectedDoctor: CareTeamTypes[];
  isModalOpenAppointment: boolean;
  particularData: CareTeamTypes | null;
}

const initialState: TeamTypes = {
  CareTeamData: [],
  loading: false,
  MyCareTeamData: [],
  SelectedDoctor: [],
  isModalOpenAppointment: false,
  particularData: null,
};

const CareTeamSlice = createSlice({
  name: "careteam",
  initialState,
  reducers: {
    toggleDoctorSelection: (state, action: PayloadAction<number>) => {
      const doctor = state.CareTeamData.find(
        (doctor) => doctor.id === action.payload
      );
      if (doctor) {
        const selectedCount = state.CareTeamData.filter(
          (doc) => doc.isChecked
        ).length;
        if (doctor.isChecked || selectedCount < 3) {
          doctor.isChecked = !doctor.isChecked;
          state.SelectedDoctor = state.CareTeamData.filter(
            (doc) => doc.isChecked
          );
        }
      }
    },
    resetDoctorSelection: (state) => {
      state.CareTeamData.forEach((doctor) => {
        doctor.isChecked = false;
      });
      state.SelectedDoctor = [];
    },
    openBookAppointment: (
      state,
      action: PayloadAction<TeamTypes["particularData"]>
    ) => {
      state.isModalOpenAppointment = true;
      state.particularData = action.payload ?? null;
    },
    closeBookAppointment: (state) => {
      state.isModalOpenAppointment = false;
    },
  },
});

export const {
  toggleDoctorSelection,
  resetDoctorSelection,
  openBookAppointment,
  closeBookAppointment,
} = CareTeamSlice.actions;
export default CareTeamSlice;
