import { Box, Modal } from '@mui/material'
import React from 'react'
import { CrossIcon } from '../../utils/functions/Icons'
import BtnLoader from './BtnLoader'
interface propTypes {
    openConfirmModal: boolean,
    CloseConfirmModal: () => void,
    confirmSubmit: () => void,
    btnLoading: boolean,
    title?: string;
    DoNotCloseNotification?: boolean
}
const ConfirmModal: React.FC<propTypes> = ({ openConfirmModal, CloseConfirmModal, confirmSubmit, btnLoading, title, DoNotCloseNotification }) => {
    return (
        <Modal
            open={openConfirmModal}
            onClose={CloseConfirmModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ width: 400 }} className={`ModalBoxStyle ${DoNotCloseNotification ? 'DoNotCloseNotification' : ''}`}>
                <div className="modalHeader">
                    <h3> {title ? title : 'Are you sure, you want to cancel this appointment'} </h3>
                    <CrossIcon onClick={CloseConfirmModal} />
                </div>
                <div className='ModalContentSection'>
                    <div className='confirmationBtns'>
                        <button className='outLineBtn' onClick={CloseConfirmModal}>No</button>
                        <button className='fillButton' disabled={btnLoading} onClick={confirmSubmit}>{btnLoading && <BtnLoader />} Yes</button>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default ConfirmModal