import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styles from "../../assets/styles/appointments.module.css"
import { FormGroup, InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import { SearchIcon } from '../../utils/functions/Icons';
import ConfirmModal from '../common/ConfirmModal';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../utils/store';
import { fetchUpcomingAppReducer } from '../../utils/store/appointment/AppointmentSlice';
import Loader from '../common/Loader';
import NoDataFound from '../common/NoDataFound';
import { cancelAppointmentApi } from '../../utils/api/services';
import { toast } from 'react-toastify';
import { Images } from '../../utils/functions/Images';
export default function UpComing() {
    const [searchValue, setSearchValue] = useState('')
    const [year, setYear] = useState<string | number>("0");
    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const { loading, UpcomingAppointment, } = useSelector((state: RootState) => state.appointment)
    const [particularData, setParticularData] = useState<appointmentTypes | null>(null)
    const dispatch = useDispatch<AppDispatch>()

    const handleCancelAppointment = (item: appointmentTypes) => {
        setParticularData(item);
        setOpenConfirmModal(true)
    }
    const CloseConfirmModal = () => {
        setOpenConfirmModal(false)
    }

    useEffect(() => {
        if (searchValue) {
            const cleanFunction = setTimeout(() => {
                dispatch(fetchUpcomingAppReducer({ search: searchValue || '', id: 1, filter: year }));
            }, 1000);
            return () => clearTimeout(cleanFunction);
        } else {
            dispatch(fetchUpcomingAppReducer({ search: searchValue || '', id: 1, filter: year }));
        }
    }, [searchValue, year]);

    const confirmSubmit = async () => {
        try {
            setBtnLoading(true)
            const result = await cancelAppointmentApi({ appointmentId: particularData?.appointmentId });
            if (result?.success) {
                setBtnLoading(false)
                toast.success(result?.message)
                dispatch(fetchUpcomingAppReducer({ search: searchValue || '', id: 1, filter: year }));
                CloseConfirmModal();
            }
            else {
                setBtnLoading(false)
                toast.error(result?.message)
            }
        } catch (error: any) {
            setBtnLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }

    return (
        <div>
            <div className={'mb-6 flex justify-between items-center w-full max-w-full'}>
                <FormGroup className="customFormGroup w-1/2">
                    <TextField
                        className={`custumTextField ${styles.InputStyle}`}
                        id="searchValue"
                        name="searchValue"
                        onChange={(e) => setSearchValue(e.target.value)}
                        value={searchValue}
                        placeholder='Search by doctor or specialising in'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormGroup>
                <FormGroup className="customFormGroup w-36">
                    <Select
                        className={`custumTextField profileInput`}
                        placeholder="Date"
                        defaultValue={"0"}
                        value={year}
                        onChange={(e) => setYear(e.target?.value)}
                    >
                        <MenuItem selected value="0">All</MenuItem>
                        <MenuItem value="1">Last Month</MenuItem>
                        <MenuItem value="2">6 Months</MenuItem>
                        <MenuItem value="3">1 Year</MenuItem>
                    </Select>
                </FormGroup>
            </div>
            {
                loading ? <Loader />
                    :
                    <TableContainer component={Paper} className='TableContainer'>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead className='custumTableHead'>
                                <TableRow className={styles.completeTableRow}>
                                    <TableCell>Patient Name</TableCell>
                                    <TableCell>Doctor Name</TableCell>
                                    <TableCell align="right">Date</TableCell>
                                    <TableCell align="right">Time</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            {
                                UpcomingAppointment?.length > 0 ?
                                    <TableBody>
                                        {
                                            UpcomingAppointment?.map((item, index) => {
                                                return (
                                                    <TableRow
                                                        key={index}
                                                        className='custumTableRow'
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            <div className={styles.doctorSec}>
                                                                <img src={item?.profilePic ? item?.profilePic : Images.avtarIcon} alt="user icon" />
                                                                {item?.patientName}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                        {item?.doctorName}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                        {item?.date}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                        {item?.time}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <p className={styles.cancelAppointment} onClick={() => handleCancelAppointment(item)}>Cancel</p>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                    :
                                    <TableBody>
                                        <TableRow
                                            className='custumTableRow'
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" colSpan={5}>
                                                <NoDataFound />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                            }
                        </Table>
                    </TableContainer>
            }

            <ConfirmModal
                openConfirmModal={openConfirmModal}
                CloseConfirmModal={CloseConfirmModal}
                confirmSubmit={confirmSubmit}
                btnLoading={btnLoading}
            />
        </div>
    );
}