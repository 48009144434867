import { combineReducers } from "@reduxjs/toolkit";
import AuthSlice from "./auth/AuthSlice";
import CommonSlice from "./common/CommonSlice";
import CareTeamSlice from "./careteam/CareTeamSlice";
import AppointmentSlice from "./appointment/AppointmentSlice";

const AllReducers = combineReducers({
  auth: AuthSlice.reducer,
  common: CommonSlice.reducer,
  careteam: CareTeamSlice.reducer,
  appointment: AppointmentSlice.reducer,
});

export default AllReducers;
