import { ChatIcon, DashboardIcon, doctorAppointmentIcon, HelpIcon, LabTestIcon,  PatientIcon, RpmAlertIcon } from "../../utils/functions/Icons";

const SidebarData: SidebarDataType[] = [
    {
        title: 'Dashboard',
        path: '/dashboard',
        icon: DashboardIcon
    },
    {
        title: 'Doctor Appointment',
        path: '/Appointments',
        icon: doctorAppointmentIcon
    },
    {
        title: 'Patients',
        path: '/patient',
        icon: PatientIcon,
        children: [
            { title: 'All patients', path: '/patient/AllPatient' },
            { title: 'My patients', path: '/patient/MyPatient' },
        ]
    },
    {
        title: 'Lab test request',
        path: '/LabTests',
        icon: LabTestIcon
    },
    {
        title: 'Chats',
        path: '/chat',
        icon: ChatIcon,
        children: [
            { title: 'Chat with patient', path: '/chat/patient' },
            { title: 'Chat with Doctor', path: '/chat/doctor' },
        ]
    },
    {
        title: 'RPM alert',
        path: '/RPMAlert',
        icon: RpmAlertIcon
    },
    {
        title: 'Help',
        path: '/help',
        icon: HelpIcon
    }
];


export default SidebarData;