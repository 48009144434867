import axios from "axios";
import axiosWithAuth from "./axiosInterceptor";
import axiosNoAuth from "./axiosNoAuth";
import {
  ADD_USERTO_WAITING,
  CANCEL_APPOINTMENT,
  CHANGE_PASSWORD,
  CHECK_STATUS_WAITING_ROOM,
  FORGOT_PASSWORD,
  GENERATE_CALLID_BY_PATIENT,
  GET_OPENTOK_ROOM_KEYS,
  GENERATE_TOKEN,
  GET_APPOINTMENT,
  GET_CITY,
  GET_DASHBOARD,
  GET_MASTER,
  GET_PROFILE,
  GET_SAVED_USER_ID,
  GET_STATE,
  HELP_FAQ,
  HELP_SEND_EMAIL,
  LOGIN,
  RESCHEDULE_APPOINTMENT,
  RESEND_OTP,
  VERIFY_OTP,
  CALL_END_BY_PATIENT,
  DISCONNECT_REASON,
  PATIENT_DETAILS_CHECK,
  UPDATE_PROFILE_ON_CALL,
  APPROVE_APPOINTMENT,
  GET_MY_PATIENT,
  GET_ALL_PATIENT,
  GET_LAB_TESTS,
  UPDATE_LAB_ORDER,
  UPLOAD_LAB_REPORT,
  UPLOAD_FILE,
  GET_LAB_PRESCRIPTION,
  UPLOAD_PRESCRIPTION,
  DELETE_PRES_FILE,
  GET_PACKAGES,
  UPDATE_APPOINTMENT,
  GET_LAB_DETAILS,
  GET_PATIENT_LOGIN_CREDENTIALS,
  CHAT_LIST_USERS,
  CHAT_HISTORY,
  CHAT_UPDATE_READ_STATUS,
  CHAT_SEARCHLIST,
  GET_NOTIFICATION,
  REMOVE_NOTIFICATION,
} from "./routes";
import axiosAuthConnect from "./connectInterceptor";

interface ApiResponse<T> {
  success: boolean;
  message: string;
  data: T;
  [key: string]: any;
}
const handleApiError = (error: any) => {
  if (error.response) {
    throw {
      status: error.response.status,
      message: error.response.data.message || "An error occurred",
      data: error.response.data,
    };
  } else if (error.request) {
    throw {
      message:
        "No response received from server. Please check your network connection.",
    };
  } else {
    throw {
      message: error.message,
    };
  }
};
export const loginApi = async (data: any): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosNoAuth.post(LOGIN, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const forgotPasswordApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosNoAuth.post(FORGOT_PASSWORD, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const verifyOtpApi = async (data: any): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosNoAuth.post(VERIFY_OTP, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const changePasswordApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosNoAuth.post(CHANGE_PASSWORD, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const resendOtpApi = async (data: any): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosNoAuth.post(RESEND_OTP, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const helpFaqApi = async (data?: any): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(
      HELP_FAQ,
      data && {
        params: data,
      }
    );
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const helpSendEmailApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(HELP_SEND_EMAIL, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getAppointmentApi = async (data?: {
  search: string;
  id: number;
  filter?: string | number;
}): Promise<ApiResponse<any>> => {
  try {
    const params = {
      params: {
        status: data?.id,
        search: data?.search ? data?.search : undefined,
        filter: data?.filter !== "0" ? data?.filter : undefined,
      },
    };
    const response = await axiosWithAuth.get(`${GET_APPOINTMENT}`, params);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getLabtestApi = async (data?: {
  search: string;
  status: string;
  filter?: string | number;
}): Promise<ApiResponse<any>> => {
  try {
    const params = {
      params: {
        status: data?.status,
        search: data?.search ? data?.search : undefined,
        filter: data?.filter !== "0" ? data?.filter : undefined,
      },
    };
    const response = await axiosWithAuth.get(`${GET_LAB_TESTS}`, params);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const rescheduleAppointmentApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(RESCHEDULE_APPOINTMENT, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const cancelAppointmentApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(CANCEL_APPOINTMENT, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const approveAppointmentApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(APPROVE_APPOINTMENT, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const updateAppointmentApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(UPDATE_APPOINTMENT, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getDashboardApi = async (): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(GET_DASHBOARD);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getProfileApi = async (): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(GET_PROFILE);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const updateProfileCallNowApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(UPDATE_PROFILE_ON_CALL, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const updateProfileApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(GET_PROFILE, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const GetMasterApi = async (data: any): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(`${GET_MASTER}/${data?.path}`);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const GetStateApi = async (): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(GET_STATE);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const GetCityApi = async (data: {
  stateCode: string;
}): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(GET_CITY, {
      params: data,
    });
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getOpenTokRoomKeys = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosAuthConnect.post(GET_OPENTOK_ROOM_KEYS, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getTokenApi = async (id: string): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(GENERATE_TOKEN, {
      params: {
        sessionId: id,
      },
    });
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getConnectTokenApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axios.post(
      `https://newapi.akosmd.in/v1/tokens`,
      data
    );
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getGenerateCallIdByPatientApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosAuthConnect.post(
      GENERATE_CALLID_BY_PATIENT,
      data
    );
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const checkStatusWaitingRoomApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosAuthConnect.post(
      CHECK_STATUS_WAITING_ROOM,
      data
    );
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getSavedUserIdApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosAuthConnect.post(GET_SAVED_USER_ID, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const addUserToWaitingApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosAuthConnect.post(ADD_USERTO_WAITING, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const callEndByPatientApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosAuthConnect.post(CALL_END_BY_PATIENT, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const disconnectReasonApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosAuthConnect.post(DISCONNECT_REASON, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const PatientDetailsCheckApi = async (
  email: string
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(
      `${PATIENT_DETAILS_CHECK}/${email}`
    );
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getMyPatientApi = async (data: {
  search: string;
  filter: string | number;
}): Promise<ApiResponse<any>> => {
  try {
    const params = {
      params: {
        search: data.search ? data.search : undefined,
        filter: data?.filter !== "0" ? data?.filter : undefined,
      },
    };
    const response = await axiosWithAuth.get(GET_MY_PATIENT, params);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getAllPatientApi = async (data: {
  search: string;
  filter: string | number;
}): Promise<ApiResponse<any>> => {
  try {
    const params = {
      params: {
        search: data.search ? data.search : undefined,
        filter: data?.filter !== "0" ? data?.filter : undefined,
      },
    };
    const response = await axiosWithAuth.get(GET_ALL_PATIENT, params);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const updateLabOrderApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(UPDATE_LAB_ORDER, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const uploadLabReportApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(UPLOAD_LAB_REPORT, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const uploadFileApi = async (data: any): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(UPLOAD_FILE, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getLabPrescriptionListApi = async (): Promise<
  ApiResponse<any>
> => {
  try {
    const response = await axiosWithAuth.get(GET_LAB_PRESCRIPTION);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const UploadPrescriptionLabApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(UPLOAD_PRESCRIPTION, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const DeletePresFileApi = async (
  id: number
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.delete(`${DELETE_PRES_FILE}/${id}`);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getPackagesApi = async (): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(GET_PACKAGES);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getLabDetailsApi = async (
  id: number
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(`${GET_LAB_DETAILS}/${id}`);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getPatientLoginCredApi = async (
  id: string | number
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(
      `${GET_PATIENT_LOGIN_CREDENTIALS}`,
      {
        patientId: id,
      }
    );
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getChatUserListApi = async (data: {
  userId: number;
  userType: number;
  search: string | undefined;
}): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(CHAT_LIST_USERS, {
      params: data,
    });
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getChatHistoryApi = async (data: {
  senderId: number;
  receiverId: number;
}): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(CHAT_HISTORY, {
      params: data,
    });
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const updateChatStatusApi = async (data: {
  senderId: number;
  receiverId: number;
  senderType: number;
  receiverType: number;
}): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(CHAT_UPDATE_READ_STATUS, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getChatSearchListApi = async (data: {
  search: string;
  type: number;
}): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(CHAT_SEARCHLIST, {
      params: data,
    });
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getNotificationApi = async (data: {
  role: string;
  referenceId: number;
  filter: string;
}): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(GET_NOTIFICATION, {
      params: data,
    });
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const removeNotificationApi = async (data: {
  notificationId: number;
}): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(REMOVE_NOTIFICATION, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
