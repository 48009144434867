import React from 'react'
import styles from "../../assets/styles/medicalrecord.module.css"
import userImg from "../../assets/images/team/1.png"

const PatientDataCard = () => {
    return (
        <section className={styles.medicalRecordUserSec}>
            <div className={styles.userNameAndImg}>
                <div className={styles.userImg}>
                    <img src={userImg} alt="user image" />
                </div>
                <div className={styles.usercontent}>
                    <h3>Mehrab Joshi</h3>
                    <p>Patient ID: 6383023820</p>
                </div>
            </div>
            <div className={`${styles.userOtherData} ${styles.fixMinWidth}`}>
                <h4>Age</h4>
                <p>34</p>
            </div>
            <div className={styles.userOtherData}>
                <h4>Date of birth</h4>
                <p>12/02/2002</p>
            </div>
            <div className={styles.userOtherData}>
                <h4>Gender</h4>
                <p>Female</p>
            </div>
            <div className={styles.userOtherData}>
                <h4>Location</h4>
                <p>Noida, UP</p>
            </div>
            <div className={styles.userOtherData}>
                <h4>Height</h4>
                <p>165 cm</p>
            </div>
            <div className={styles.userOtherData}>
                <h4>Weight</h4>
                <p>80kg</p>
            </div>
            <div className={styles.userOtherData}>
                <h4>Blood Group</h4>
                <p>A+</p>
            </div>
        </section>
    )
}

export default PatientDataCard