import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { convertUTCtoIST, decodeToken } from "../../functions/common";

interface AuthState {
  token: string | null;
  refreshToken: string | null;
  connect_token: string | null;
  LoginData: LoginResponseTypes | null;
  isProfileComplete: boolean;
}
const _loginData = localStorage.getItem("patientInfo");
const parsedLoginData = _loginData ? JSON.parse(_loginData) : null;
const _ProfileCom = localStorage.getItem("isProfileCompleted");
const _ParseProfileCom = _ProfileCom ? JSON.parse(_ProfileCom) : null;

const initialState: AuthState = {
  token: Cookies.get("token") || null,
  refreshToken: Cookies.get("refreshToken") || null,
  connect_token: Cookies.get("connect_token") || null,
  LoginData: parsedLoginData,
  isProfileComplete: _ParseProfileCom,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      const decodedToken = decodeToken(action.payload);
      if (decodedToken && decodedToken.exp) {
        const expTimestamp = decodedToken.exp * 1000;
        const expiryDateUTC = new Date(expTimestamp);
        const expiryDateIST = convertUTCtoIST(expiryDateUTC);
        Cookies.set("token", action.payload, { expires: expiryDateIST });
      }
    },
    setAuthConnectToken: (state, action: PayloadAction<string>) => {
      state.connect_token = action.payload;
      if (action.payload) {
        Cookies.set("connect_token", action.payload);
      }
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      state.refreshToken = action.payload;
      Cookies.set("refreshToken", action.payload);
    },
    setPatientLoginData: (
      state,
      action: PayloadAction<AuthState["LoginData"]>
    ) => {
      state.LoginData = action.payload ?? null;
    },
    setIsProfileComplete: (
      state,
      action: PayloadAction<AuthState["isProfileComplete"]>
    ) => {
      state.isProfileComplete = action?.payload;
    },

    clearAuth: (state) => {
      state.token = null;
      state.refreshToken = null;
      Cookies.remove("token");
      Cookies.remove("refreshToken");
    },
  },
});

export const {
  setAuthToken,
  setRefreshToken,
  clearAuth,
  setAuthConnectToken,
  setPatientLoginData,
  setIsProfileComplete
} = authSlice.actions;
export default authSlice;
