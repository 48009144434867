import React, { useState } from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import { useNavigate } from 'react-router-dom'
import { BackIcon } from '../../utils/functions/Icons';
import styles from "../../assets/styles/rpmmanagement.module.css"
import InputFile from '../../components/common/InputFile';
import { FormGroup, FormLabel, MenuItem, Select, TextareaAutosize, TextField } from '@mui/material';
import { handleKeyPress } from '../../utils/functions/common';
import BtnLoader from '../../components/common/BtnLoader';
import PatientDataCard from '../../components/common/PatientDataCard';
interface rpmForm {
    email: string,
    mobile: string,
    emergencyMobile: string;
    status: string;
    followUp: string;
}
const RPMAlertManagementDetails = () => {
    const [formState, setFormState] = useState<rpmForm>({
        email: "",
        mobile: "",
        emergencyMobile: "",
        status: "",
        followUp: '',
    })
    const [btnLoading, setBtnLoading] = useState(false)
    const navigate = useNavigate();

    const handleInputChange: HandleInputChangeType = (e) => {
        const { name, value } = e.target;
        setFormState({ ...formState, [name]: value })
    };
    const handleSelectChange: HandleSelectChangeType = (e) => {
        const { name, value } = e.target;
        setFormState({ ...formState, [name]: value })
    };
    const handleSubmit = () => { }
    return (
        <CustomContainer>
            <div className={'AllPageMain'}>
                <div className={'HeadAndButton'}>
                    <h2 className='flex items-center gap-4 cursor-pointer' onClick={() => navigate(-1)}><BackIcon />RPM alert</h2>
                </div>
                <div className={styles.rpmDetailsPageMain}>
                    <div className='mb-8'>
                        <PatientDataCard />
                    </div>
                    <form noValidate={false} onSubmit={handleSubmit}>
                        <div className="grid grid-cols-3 gap-y-6 gap-x-16">
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Email Address <span className='errorMsg'>*</span></FormLabel>
                                <TextField
                                    type='email'
                                    className={`custumTextField profileInput`}
                                    name="email"
                                    required
                                    value={formState.email ?? ''}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Mobile</FormLabel>
                                <TextField
                                    type="text"
                                    className={`custumTextField profileInput`}
                                    name="mobile"
                                    required
                                    value={formState.mobile}
                                    inputProps={{
                                        inputMode: 'numeric',
                                        maxLength: 10,
                                    }}
                                    onKeyPress={handleKeyPress}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Emergency Contact Number</FormLabel>
                                <TextField
                                    type="text"
                                    className={`custumTextField profileInput`}
                                    name="emergencyMobile"
                                    required
                                    placeholder='Enter your contact number'
                                    value={formState.emergencyMobile}
                                    inputProps={{
                                        inputMode: 'numeric',
                                        maxLength: 10,
                                    }}
                                    onKeyPress={handleKeyPress}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Status</FormLabel>
                                <Select
                                    className={`custumTextField profileInput`}
                                    placeholder="Select gender"
                                    value={formState.status}
                                    name='status'
                                    onChange={handleSelectChange}
                                >
                                    <MenuItem value="Contacted">Contacted</MenuItem>
                                    <MenuItem value="Referred">Referred</MenuItem>
                                    <MenuItem value="Resolved">Resolved</MenuItem>
                                </Select>
                            </FormGroup>
                        </div>
                        <div className='grid grid-cols-1 gap-y-6 gap-x-16 mt-6'>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Follow up</FormLabel>
                                <TextareaAutosize
                                    minRows={5}
                                    className={`custumTextField profileInput w-full`}
                                    placeholder="Following up with the patient"
                                    name="message"
                                    required
                                    value={formState?.followUp}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                            <FormGroup className="customFormGroup">
                                <FormLabel className="customFormLabel">Remarks</FormLabel>
                                <TextareaAutosize
                                    minRows={5}
                                    className={`custumTextField profileInput w-full`}
                                    placeholder="Write the remarks......."
                                    name="message"
                                    required
                                    value={formState?.followUp}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                        </div>
                        <div className="mt-8">
                            <button className='fillButton'> {btnLoading && <BtnLoader />} Save profile</button>
                        </div>
                    </form>
                </div>
            </div>
        </CustomContainer>
    )
}

export default RPMAlertManagementDetails