import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Login from './pages/Login'
import ScrollToTop from './components/common/ScrollToTop'
import Layout from './components/layout'
import ForgotPassword from './pages/ForgotPassword'
import LoginOtp from './pages/LoginOtp'
import ChangePassword from './pages/ChangePassword'
import Dashboard from './pages/AuthPages/Dashboard'
import MyAppointment from './pages/AuthPages/MyAppointment'
import Help from './pages/AuthPages/Help'
import RpmAlertManagement from './pages/AuthPages/RpmAlertManagement'
import RPMAlertManagementDetails from './pages/AuthPages/RPMAlertManagementDetails'
import ProtectedRoute from './components/common/ProtectedRoute'
import { useSelector } from 'react-redux'
import { RootState } from './utils/store'
import LabTests from './pages/AuthPages/LabTests'
import LabTestDetails from './pages/AuthPages/LabTestDetails'
import ProfilePage from './pages/AuthPages/Profile'
import MyPatient from './pages/AuthPages/MyPatient'
import AllPatient from './pages/AuthPages/AllPatient'
import DoctorChat from './pages/AuthPages/DoctorChat'
import PatientChat from './pages/AuthPages/PatientChat'
import Setting from './pages/AuthPages/Setting'

const App = () => {
  const token = useSelector((state: RootState) => state.auth.token);
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/otp' element={<LoginOtp />} />
        <Route path='/change-password' element={<ChangePassword />} />
        <Route path="/*"
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }>
          <Route path='myaccount/profile' element={<ProfilePage />} />
          <Route path='dashboard' element={<Dashboard />} />
          <Route path='Appointments' element={<MyAppointment />} />
          <Route path='help' element={<Help />} />
          <Route path='RPMAlert' element={<RpmAlertManagement />} />
          <Route path='RPMAlert/details' element={<RPMAlertManagementDetails />} />
          <Route path='LabTests' element={<LabTests />} />
          <Route path='LabTests/details' element={<LabTestDetails />} />
          <Route path='patient/MyPatient' element={<MyPatient />} />
          <Route path='patient/AllPatient' element={<AllPatient />} />
          <Route path='chat/doctor' element={<DoctorChat />} />
          <Route path='chat/patient' element={<PatientChat />} />
          <Route path='myaccount/settings' element={<Setting />} />
          <Route path='*' element={<Dashboard />} />
          <Route
            path="/*"
            element={<Navigate to={"/dashboard"} replace />}
          />
        </Route>
        <Route
          path="/*"
          element={<Navigate to={!token ? "/login" : '/'} replace />}
        />
      </Routes>
    </>
  )
}

export default App