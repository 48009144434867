import { FC } from "react"
import styles from "../../assets/styles/chat.module.css"
interface Props {
    description: string
}
const NoChat: FC<Props> = ({description}) => {
    return (
        <div className={styles.NoChat}>
            <h3>Select to Chat</h3>
            <p>{description}</p>
        </div>
    )
}

export default NoChat