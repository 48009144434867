import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styles from "../../assets/styles/appointments.module.css"
import { FormGroup, InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import { SearchIcon } from '../../utils/functions/Icons';
import { useEffect, useState } from 'react';
import Loader from '../common/Loader';
import NoDataFound from '../common/NoDataFound';
import DocumentViewModal from '../savedDocuments/DocumentViewModal';
import { Images } from '../../utils/functions/Images';
import { getMyPatientApi, getPackagesApi, getPatientLoginCredApi } from '../../utils/api/services';
import { toast } from 'react-toastify';
import { PATIENT_PORTAL } from '../../utils/api/routes';

export default function MyPatientTable() {
    const [year, setYear] = useState<string | number>("0");
    const [searchValue, setSearchValue] = useState('')
    const [patientData, setPatientData] = useState<PatientDetails[] | []>([])
    const [loading, setLoading] = useState(false);
    const [packagesData, setPackagesData] = useState<packagesRes[] | []>([])

    const fetchPackagesData = async () => {
        try {
            const result = await getPackagesApi()
            if (result?.success) {
                setPackagesData(result?.packages)
            }
            else {
                setPackagesData([])
            }
        } catch (error) {

        }
    }
    useEffect(() => {
        fetchPackagesData()
    }, [])

    const fetchMyPatientApi = async (data: { search: string, filter: string | number }) => {
        try {
            setLoading(true)
            const result = await getMyPatientApi(data);
            if (result?.success) {
                setLoading(false)
                setPatientData(result?.userDetails)
            }
            else {
                setLoading(false)
                setPatientData([])
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.data?.errors || error?.message);

        }
    }
    useEffect(() => {
        if (searchValue) {
            const cleanFunction = setTimeout(() => {
                fetchMyPatientApi({ search: searchValue || '', filter: year });
            }, 1000);
            return () => clearTimeout(cleanFunction);
        } else {
            fetchMyPatientApi({ search: searchValue || '', filter: year });
        }
    }, [searchValue, year]);


    const handleVisitPatient = async (item: PatientDetails) => {
        try {
            if (item?.uniquePatientId) {
                const result = await getPatientLoginCredApi(item?.patientId)
                if (result?.success) {
                    if (result?.token && result?.loggedBy) {
                        window.open(`${PATIENT_PORTAL}/?token=${result?.token}&loggedBy=${result?.loggedBy}`)
                    }
                }
            }
        } catch (error: any) {
            toast.error(error?.data?.errors || error?.message);
        }
    }

    return (
        <div>
            <div className={'mb-6 flex justify-between items-center w-full max-w-full'}>
                <FormGroup className="customFormGroup w-1/2">
                    <TextField
                        className={`custumTextField ${styles.InputStyle}`}
                        id="searchValue"
                        name="searchValue"
                        onChange={(e) => setSearchValue(e.target.value)}
                        value={searchValue}
                        placeholder='Search by doctor or specialising in'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormGroup>
                <FormGroup className="customFormGroup w-36">
                    <Select
                        className={`custumTextField profileInput`}
                        placeholder="Date"
                        defaultValue={"0"}
                        value={year}
                        onChange={(e) => setYear(e.target?.value)}
                    >
                        <MenuItem selected value="0">All</MenuItem>
                        {
                            packagesData?.map((item, index) => {
                                return (
                                    <MenuItem key={index} value={item?.id}>{item?.packageName}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormGroup>
            </div>
            {
                loading ? <Loader />
                    :
                    <TableContainer component={Paper} className='TableContainer'>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead className='custumTableHead'>
                                <TableRow className={styles.completeTableRow}>
                                    <TableCell className='whitespace-nowrap'>Patient Name</TableCell>
                                    <TableCell className='whitespace-nowrap'>Patient ID</TableCell>
                                    <TableCell align="right" className='whitespace-nowrap'>Company Name</TableCell>
                                    <TableCell align="right" className='whitespace-nowrap'>Package</TableCell>
                                    <TableCell align="right" className='whitespace-nowrap'>Contact</TableCell>
                                    <TableCell align="right" className='whitespace-nowrap'></TableCell>
                                </TableRow>
                            </TableHead>
                            {
                                patientData?.length > 0 ?
                                    <TableBody>
                                        {
                                            patientData?.map((item, index) => {
                                                return (
                                                    <TableRow
                                                        key={index}
                                                        className='custumTableRow'
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        title="View Details"
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            <div className={styles.doctorSec}>
                                                                <img src={item?.profilePic ? item?.profilePic : Images.avtarIcon} alt="user icon" />
                                                                {item?.patientName}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item?.uniquePatientId}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item?.companyName}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item?.packageName}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {item?.phone}
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            className={styles.cancelAppointment}
                                                            onClick={() => handleVisitPatient(item)}
                                                        >
                                                            View Details
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }

                                    </TableBody>
                                    :
                                    <TableBody>
                                        <TableRow
                                            className='custumTableRow'
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" colSpan={5}>
                                                <NoDataFound />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                            }
                        </Table>
                    </TableContainer>
            }
            <DocumentViewModal />
        </div>
    );
}