import React, { Dispatch, FC, SetStateAction } from 'react'
import styles from "../../assets/styles/chat.module.css"
import { FormGroup, InputAdornment, TextField } from '@mui/material'
import { SearchIcon } from '../../utils/functions/Icons'
import { Images } from '../../utils/functions/Images'

interface Props {
    userList: ChatPartner[] | [];
    handleParticularUser: (data: ChatPartner) => void;
    setSearchValue: Dispatch<SetStateAction<string>>;
    searchValue: string;
    particularUser: ChatPartner | null;
}

const PatientChatSidebar: FC<Props> = ({
    userList,
    handleParticularUser,
    setSearchValue,
    searchValue,
    particularUser
}) => {
    return (
        <div className={styles.sidebarMain}>
            <div className={styles.sidebarHead}>
                <h3>Messages</h3>
            </div>
            <div className={styles.searchBox}>
                <FormGroup className="customFormGroup">
                    <TextField
                        className={`custumTextField chatSearchInput`}
                        id="searchValue"
                        name="searchValue"
                        value={searchValue}
                        placeholder='Search chat'
                        onChange={(e) => setSearchValue(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormGroup>
            </div>
            <ul className={styles.userListMain}>
                {
                    userList?.map((item, index) => {
                        return (
                            <li className={item?.chatPartnerId === particularUser?.chatPartnerId ? styles.ActiveUser : ''} key={index} onClick={() => handleParticularUser(item)}>
                                <div className={styles.userImg}>
                                    <img src={item?.profilePic ? item?.profilePic : Images.avtarIcon} alt="avtarIcon" />
                                    {
                                        item?.isOnline !== undefined &&
                                        <div className={`${styles.dot} ${item?.isOnline === 1 ? styles.onlineDot : ''}`}></div>
                                    }
                                </div>
                                <div className={styles.userName}>
                                    <h4>{item?.chatPartnerName}</h4>
                                    {
                                        item?.lastMessageType === 2 ?
                                            <p>Attachment</p>
                                            :
                                            <p>{item?.lastMessage}</p>
                                    }
                                    {
                                        (item?.unreadMessages !== "0" && item?.unreadMessages !== undefined && item?.chatPartnerId !== particularUser?.chatPartnerId) &&
                                        <div className={styles.unReadMsg}>{item?.unreadMessages}</div>
                                    }
                                </div>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default PatientChatSidebar