import React, { useEffect, useState } from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import { BackIcon, ExpandedMoreIcon, ExpandMoreIcon } from '../../utils/functions/Icons'
import { useLocation, useNavigate } from 'react-router-dom'
import styles from "../../assets/styles/labtest.module.css"
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { getLabDetailsApi } from '../../utils/api/services'
import { toast } from 'react-toastify'

const LabTestDetails = () => {
    const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({});
    const [labDetailsData, setLabDetailsData] = useState<LabOrderRes | null>(null)
    const [loading, setLoading] = useState(false)
    const location = useLocation();
    const navigate = useNavigate();

    const handleExpansion = (index: number) => {
        setExpanded((prevExpanded) => ({
            [index]: !prevExpanded[index]
        }));
    };

    const fetchLabDetails = async (id: number) => {
        try {
            setLoading(true)
            const result = await getLabDetailsApi(id);
            if (result?.success) {
                setLoading(false)
                setLabDetailsData(result?.labOrders && result?.labOrders[0])
            }
            else {
                setLoading(false)
                setLabDetailsData(null)
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }

    useEffect(() => {
        if (location.state?.id) {
            fetchLabDetails(location.state?.id)
        }
    }, [location.pathname])
    return (
        <CustomContainer>
            <div className='AllPageMain'>
                <div className='HeadAndButton'>
                    <h2 className='flex items-center gap-4 cursor-pointer' onClick={() => navigate(-1)}><BackIcon />{location?.state?.labName}</h2>
                    <button className='fillButton'>Status : {labDetailsData?.orderStatus}</button>
                </div>
                <div className={styles.labtestDetails}>
                    <section className={`grid grid-cols-7 gap-6 ${styles.detailsLeftSec}`}>
                        {/* <div className={`col-span-4 ${styles.testContent}`}>
                            <h4>Name of the test</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vitae ipsum sed velit efficitur tincidunt.  Nullam posuere justo nec nunc feugiat, eget vestibulum purus fr ingilla. Quisque bibendum augue ac justo fermentum, nec ullamcorper justo consequat. Duis vitae
                                lorem sed eros suscipit ultrices. Sed auctor sapien vitae </p>
                        </div> */}
                        <div className={`col-span-3`}>
                            <div className='grid grid-cols-5 gap-6'>
                                <div className={`col-span-2 ${styles.sampleCollection}`}>
                                    <h4>Total Price</h4>
                                    <p>₹ {labDetailsData?.totalPrice}</p>
                                </div>
                                <div className={`col-span-3 ${styles.sampleCollection} ${styles.sampleCollection2}`}>
                                    <h4>Date : {labDetailsData?.bookingDate}</h4>
                                    <h4>time : {labDetailsData?.bookingTime}</h4>
                                    <p>Address : {labDetailsData?.bookingAddress}</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='grid grid-cols-7 gap-6'>
                        <div className={`col-span-4 ${styles.parameter}`}>
                            <div className={styles.parameterHead}>
                                <h4>Test parameters</h4>
                            </div>
                            <div>
                                {
                                    labDetailsData?.orderDetails?.map((item, index) => {
                                        const isExpanded = !!expanded[index];
                                        return (
                                            <Accordion
                                                expanded={isExpanded}
                                                onChange={() => handleExpansion(index)}
                                                className="CustomAccordion"
                                                key={index}
                                            >
                                                <AccordionSummary
                                                    expandIcon={
                                                        isExpanded ?
                                                            <ExpandedMoreIcon />
                                                            : <ExpandMoreIcon />
                                                    }
                                                    aria-controls="helpContent"
                                                    id={`helpContent-${index}`}
                                                    className="AccordionSummary"
                                                >
                                                    <Typography className="AccordionHeader">{item?.name}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails className="accordionDetails">
                                                    <div className={styles.AccDetails}>
                                                        <h3>Type: <span>{item?.type}</span></h3>
                                                        <h3>Mode: <span>{item?.mode}</span></h3>
                                                        <h3>Price: <span> ₹{item?.price}</span></h3>
                                                        <h3>Discount: <span>- ₹{item?.discount}</span></h3>
                                                        <h3>Total Price: <span> ₹{item?.total}</span></h3>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        )
                                    })
                                }

                            </div>
                        </div>
                        {/* <div className='col-span-3'>
                            <div className={styles.requirementtestBox}>
                                <h4>Requirements before the test</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vitae ipsum sed velit efficitur tincidunt.
                                    Nullam posuere justo nec nunc feugiat, eget vestibulum purus fr
                                    ingilla. Quisque bibendum augue ac justo fermentum, nec ullamcorper justo consequat. Duis vitae
                                    lorem sed eros suscipit ultrices. Sed auctor sapien vitae s
                                    apien gravida, vel tincidunt velit feugiat. Integer ultricies risus vel mauris pharetra, sit </p>
                            </div>
                        </div> */}
                    </section>
                </div>
            </div>
        </CustomContainer>
    )
}

export default LabTestDetails