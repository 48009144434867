import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface StateTypes {
  sidebarToggle: boolean;
  particularPrescription: viewPrescriptionTypes | null;
  viewDocument: boolean;
}
const initialState: StateTypes = {
  sidebarToggle: false,
  particularPrescription: null,
  viewDocument: false,
};
const CommonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    handleToggle: (state) => {
      state.sidebarToggle = !state.sidebarToggle;
    },
    handleCloseDocument: (state) => {
      state.viewDocument = false;
    },
    handleViewDocument: (
      state,
      action: PayloadAction<StateTypes["particularPrescription"]>
    ) => {
      state.viewDocument = true;
      state.particularPrescription = action.payload ?? null;
    },
  },
});

export const { handleToggle, handleViewDocument, handleCloseDocument } =
  CommonSlice.actions;
export default CommonSlice;
