import { Box, FormGroup, FormLabel, Modal } from '@mui/material'
import React, { FormEvent } from 'react'
import { CrossIcon } from '../../utils/functions/Icons'
import BtnLoader from '../common/BtnLoader'
import InputFile from '../common/InputFile'
interface propTypes {
    openConfirmModal: boolean,
    CloseConfirmModal: () => void,
    confirmSubmit: (e: FormEvent<HTMLFormElement>) => void,
    btnLoading: boolean,
    title?: string,
    handleFileChange: (name: string, file: File | null) => void;
    file: string
}
const UploadLabReportModal: React.FC<propTypes> = ({ openConfirmModal,
    CloseConfirmModal,
    confirmSubmit,
    btnLoading,
    title,
    handleFileChange,
    file
}) => {
    return (
        <Modal
            open={openConfirmModal}
            onClose={CloseConfirmModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ width: 400 }} className={"ModalBoxStyle"}>
                <div className="modalHeader">
                    <h3> {title ? title : 'Are you sure, you want to cancel this appointment'} </h3>
                    <CrossIcon onClick={CloseConfirmModal} />
                </div>
                <div className='ModalContentSection'>
                    <form onSubmit={confirmSubmit}>
                        <div>
                            <FormGroup className="customFormGroup">
                                <InputFile
                                    title={file !== '' ? 'File Uploaded' : 'Upload file'}
                                    colorBorder={true}
                                    id={"profile_image"}
                                    name={"profile_image"}
                                    onlyImg={false}
                                    handleFileChange={handleFileChange}
                                />
                            </FormGroup>
                        </div>
                        <button type='submit' className='fillButton mt-8' disabled={btnLoading}>{btnLoading && <BtnLoader />} Submit</button>
                    </form>
                </div>
            </Box>
        </Modal>
    )
}

export default UploadLabReportModal