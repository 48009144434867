import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    FormGroup,
    InputAdornment,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styles from "../../assets/styles/appointments.module.css";
import { SearchIcon } from '../../utils/functions/Icons';
import ConfirmModal from '../common/ConfirmModal';
import NoDataFound from '../common/NoDataFound';
import Loader from '../common/Loader';
import { approveAppointmentApi, getLabtestApi, updateLabOrderApi } from '../../utils/api/services';
import { AppDispatch } from '../../utils/store';

export default function Requested() {
    const [searchValue, setSearchValue] = useState('');
    const [year, setYear] = useState<string | number>('0');
    const [status, setStatus] = useState<string | number>('0');
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [labTestData, setLabTestData] = useState<LabTest[]>([]);
    const [actionValues, setActionValues] = useState<string[]>([]); // Store actions by index
    const [particularData, setParticularData] = useState<LabTest | null>(null);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const handleAction = (event: SelectChangeEvent<string>, index: number, item: LabTest) => {
        event.stopPropagation();
        const newActionValues = [...actionValues];
        newActionValues[index] = event.target.value;
        setActionValues(newActionValues);

        if (event.target.value !== '0') {
            setParticularData(item);
            setOpenConfirmModal(true);
        }
    };

    const CloseConfirmModal = () => {
        setOpenConfirmModal(false);
    };

    const fetchLabtestApi = async (data: { search: string; status: string; filter: string | number }) => {
        try {
            setLoading(true);
            const result = await getLabtestApi(data);
            if (result?.success) {
                setLabTestData(result?.labTests);
                setActionValues(new Array(result?.labTests.length).fill('0')); // Initialize action values
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (searchValue) {
            const cleanFunction = setTimeout(() => {
                fetchLabtestApi({ search: searchValue, status: 'pending', filter: year });
            }, 1000);
            return () => clearTimeout(cleanFunction);
        } else {
            fetchLabtestApi({ search: searchValue, status: 'pending', filter: year });
        }
    }, [searchValue, year]);

    const confirmSubmit = async () => {
        try {
            setBtnLoading(true)
            const _Status = actionValues[particularData ? labTestData.indexOf(particularData) : -1] === "Approve" ? 'confirmed' : 'cancelled'
            const result = await updateLabOrderApi({ orderId: particularData?.labOrderId, status: _Status });
            if (result?.success) {
                setBtnLoading(false)
                toast.success(result?.message)
                fetchLabtestApi({ search: searchValue, status: 'pending', filter: year });
                CloseConfirmModal();
            }
            else {
                setBtnLoading(false)
                toast.error(result?.message)
            }
        } catch (error: any) {
            setBtnLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }

    const handleViewDetails = (item: LabTest) => {
        navigate('/LabTests/details', {
            state: {
                id: item?.labOrderId,
                labName: item?.labName
            }
        });
    };

    return (
        <div>
            <div className="mb-6 flex justify-between items-center w-full max-w-full">
                <FormGroup className="customFormGroup w-1/2">
                    <TextField
                        className={`custumTextField ${styles.InputStyle}`}
                        id="searchValue"
                        name="searchValue"
                        onChange={(e) => setSearchValue(e.target.value)}
                        value={searchValue}
                        placeholder="Search"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormGroup>
                <FormGroup className="customFormGroup w-36">
                    <Select
                        className={`custumTextField profileInput`}
                        placeholder="Date"
                        defaultValue={"0"}
                        value={year}
                        onChange={(e) => setYear(e.target?.value)}
                    >
                        <MenuItem selected value="0">All</MenuItem>
                        <MenuItem value="1">Last Month</MenuItem>
                        <MenuItem value="2">6 Months</MenuItem>
                        <MenuItem value="3">1 Year</MenuItem>
                    </Select>
                </FormGroup>
            </div>
            {
                loading ? <Loader />
                    :
                    <TableContainer component={Paper} className="TableContainer">
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead className="custumTableHead">
                                <TableRow className={styles.completeTableRow}>
                                    <TableCell>Patient Name</TableCell>
                                    <TableCell align="right">City</TableCell>
                                    <TableCell align="right">Lab Name</TableCell>
                                    <TableCell align="right">Date</TableCell>
                                    <TableCell align="right">Time</TableCell>
                                    <TableCell align="right">Type of test</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            {
                                labTestData?.length > 0 ?
                                    <TableBody>
                                        {
                                            labTestData.map((item, index) => (
                                                <TableRow
                                                    key={index}
                                                    onClick={() => handleViewDetails(item)}
                                                    className="custumTableRow cursor-pointer"
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {item.patientName}
                                                    </TableCell>
                                                    <TableCell align="right">{item.labCityName}</TableCell>
                                                    <TableCell align="right">{item.labName}</TableCell>
                                                    <TableCell align="right">{item.bookingDate}</TableCell>
                                                    <TableCell align="right">{item.bookingTime}</TableCell>
                                                    <TableCell align="right">
                                                        <button className={`outLineBtn w-full ${styles.downloadPresBtn}`}>
                                                            {item.type}
                                                        </button>
                                                    </TableCell>
                                                    <TableCell >
                                                        <FormGroup className="customFormGroup w-36">
                                                            <Select
                                                                className={`custumTextField profileInput`}
                                                                placeholder="Date"
                                                                defaultValue={"0"}
                                                                value={actionValues[index] || '0'}
                                                                onChange={(event) => handleAction(event, index, item)}
                                                                onClick={(e) => e.stopPropagation()}
                                                            >
                                                                <MenuItem selected value="0">No Action</MenuItem>
                                                                <MenuItem value="Approve">Approve</MenuItem>
                                                                <MenuItem value="Cancel">Cancel</MenuItem>
                                                            </Select>
                                                        </FormGroup>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                    :
                                    <TableBody>
                                        <TableRow className="custumTableRow" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row" colSpan={5}>
                                                <NoDataFound />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                            }
                        </Table>
                    </TableContainer>
            }

            <ConfirmModal
                openConfirmModal={openConfirmModal}
                CloseConfirmModal={CloseConfirmModal}
                confirmSubmit={confirmSubmit}
                btnLoading={btnLoading}
                title={`Are you sure you want to ${actionValues[particularData ? labTestData.indexOf(particularData) : -1]} this lab test?`}
            />
        </div>
    );
}
